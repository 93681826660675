.dotsWrapper {
  box-sizing: border-box;
}

.dotsWrapper *,
.dotsWrapper *:before,
.dotsWrapper *:after {
  box-sizing: inherit;
}

.dots {
  position: relative;
  width: 100%;
  padding-top: 6.6%;
}

.dots .dot {
  width: 6.6%;
  padding-top: 6.6%;
  animation: loader 2s ease-in-out infinite;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.dotsText {
  width: 100%;
  text-align: center;
}

@keyframes loader {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(1420%);
  }
  100% {
    transform: translateX(0);
  }
}
